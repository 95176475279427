import PropTypes from "prop-types";
import { Box, Chip, Stack, Typography } from "@mui/material";
import {
  blue,
  green,
  indigo,
  purple,
  teal,
  cyan,
  magenta,
  pink,
  brown,
  gray,
  olive,
  lime,
  deepOrange,
  amber,
  skyBlue,
  coral,
  mint,
  lavender,
  peach,
  plum,
  sage,
  lemon,
  terracotta,
  aqua,
} from "src/theme/colors";

export const OptionsColorPreset = (props) => {
  const { onChange, value } = props;

  const options = [
    {
      label: "Green",
      value: "green",
      color: green.main,
    },
    {
      label: "Blue",
      value: "blue",
      color: blue.main,
    },
    {
      label: "Indigo",
      value: "indigo",
      color: indigo.main,
    },
    {
      label: "Purple",
      value: "purple",
      color: purple.main,
    },
    { label: "Teal", value: "teal", color: teal.main },
    { label: "Cyan", value: "cyan", color: cyan.main },
    { label: "Magenta", value: "magenta", color: magenta.main },
    { label: "Pink", value: "pink", color: pink.main },
    { label: "Brown", value: "brown", color: brown.main },
    { label: "Gray", value: "gray", color: gray.main },
    { label: "Olive", value: "olive", color: olive.main },
    { label: "Lime", value: "lime", color: lime.main },
    { label: "Amber", value: "amber", color: amber.main },
    { label: "Deep Orange", value: "deepOrange", color: deepOrange.main },
    { label: "Sky Blue", value: "skyBlue", color: skyBlue.main },
    { label: "Coral", value: "coral", color: coral.main },
    { label: "Mint", value: "mint", color: mint.main },
    { label: "Lavender", value: "lavender", color: lavender.main },
    { label: "Peach", value: "peach", color: peach.main },
    { label: "Plum", value: "plum", color: plum.main },
    { label: "Sage", value: "sage", color: sage.main },
    { label: "Lemon", value: "lemon", color: lemon.main },
    { label: "Terracotta", value: "terracotta", color: terracotta.main },
    { label: "Aqua", value: "aqua", color: aqua.main },
  ];

  return (
    <Stack spacing={1}>
      <Typography color="text.secondary" variant="overline">
        Primary Color
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      >
        {options.map((option) => (
          <Chip
            icon={
              <Box
                sx={{
                  backgroundColor: option.color,
                  borderRadius: "50%",
                  flexShrink: 0,
                  height: 24,
                  width: 24,
                }}
              />
            }
            key={option.value}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: "transparent",
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: "primary.main",
              }),
              justifyContent: "flex-start",
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsColorPreset.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};
