import { useCallback } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
// import CreditCard01Icon from '@untitled-ui/icons-react/build/esm/CreditCard01';
// import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
import User03Icon from "@untitled-ui/icons-react/build/esm/User03";
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  SvgIcon,
  Popover,
  Typography,
} from "@mui/material";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { useAuthContext } from "src/contexts/auth-context";
import Settings04 from "@untitled-ui/icons-react/build/esm/Settings04";
import Settings01 from "@untitled-ui/icons-react/build/esm/Settings01";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const auth = useAuth();
  const { user } = useAuthContext();

  const hostname = window.location.hostname;
  const isAppUrl = hostname.includes("app");
  const homePath = isAppUrl ? paths.appIndex : paths.index;

  const handleLogout = useCallback(async () => {
    try {
      localStorage.clear();
      window.location.href = homePath;
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          {user.first_name} {user.last_name}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user.email ? user.email : user.phone}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1, mx: "auto" }}>
        <ListItemButton
          href={paths.profile}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">My Profile</Typography>} />
        </ListItemButton>
        {user.is_superuser && (
          <ListItemButton
            href={paths.tenants}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <Settings04 />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">Manage Tenants</Typography>} />
          </ListItemButton>
        )}
        {(user.is_superuser || user.role === "admin" || user.role === "manager") && (
          <ListItemButton
            href={paths.schoolSettings}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <Settings01 />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">School Settings</Typography>} />
          </ListItemButton>
        )}
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Logout
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
