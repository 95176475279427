import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import BottomNav from "../../vertical-layout/bottom-nav";
import { ParentTopNav } from "../parent-top-nav";

const ParentLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
});

const ParentLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const ParentLayout = (props) => {
  const { children, navColor } = props;

  return (
    <>
      <ParentTopNav color={navColor} />
      <BottomNav />

      <ParentLayoutRoot>
        <ParentLayoutContainer>{children}</ParentLayoutContainer>
      </ParentLayoutRoot>
    </>
  );
};

ParentLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
};
