import { alpha } from '@mui/material/styles';

const withAlphas = (color) => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.30),
    alpha50: alpha(color.main, 0.50)
  };
};

export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927'
};

export const blue = withAlphas({
  lightest: '#F5F8FF',
  light: '#EBEFFF',
  main: '#2970FF',
  dark: '#004EEB',
  darkest: '#00359E',
  contrastText: '#FFFFFF'
});

export const green = withAlphas({
  lightest: '#F6FEF9',
  light: '#EDFCF2',
  main: '#16B364',
  dark: '#087443',
  darkest: '#084C2E',
  contrastText: '#FFFFFF'
});

export const indigo = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#6366F1',
  dark: '#4338CA',
  darkest: '#312E81',
  contrastText: '#FFFFFF'
});

export const purple = withAlphas({
  lightest: '#F9F5FF',
  light: '#F4EBFF',
  main: '#9E77ED',
  dark: '#6941C6',
  darkest: '#42307D',
  contrastText: '#FFFFFF'
});

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF'
});

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF'
});

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF'
});

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF'
});

export const teal = withAlphas({
  lightest: '#F0FCFC',
  light: '#CCEAE7',
  main: '#2596BE',
  dark: '#00678F',
  darkest: '#004561',
  contrastText: '#FFFFFF'
});

export const cyan = withAlphas({
  lightest: '#E3FCFF',
  light: '#B3F5FF',
  main: '#00B8D4',
  dark: '#0088A3',
  darkest: '#005F73',
  contrastText: '#FFFFFF'
});

export const magenta = withAlphas({
  lightest: '#FDF2F8',
  light: '#FCE7F3',
  main: '#EC4899',
  dark: '#B41B69',
  darkest: '#7C134A',
  contrastText: '#FFFFFF'
});

export const pink = withAlphas({
  lightest: '#FDF2F8',
  light: '#FCE7F3',
  main: '#FF0080',
  dark: '#C51162',
  darkest: '#880E4F',
  contrastText: '#FFFFFF'
});

export const brown = withAlphas({
  lightest: '#F9F6F0',
  light: '#E9DFCC',
  main: '#82582E',
  dark: '#553C1E',
  darkest: '#342712',
  contrastText: '#FFFFFF'
});

export const gray = withAlphas({ // Note: Renamed this from 'grey' for consistency
  lightest: '#F8F9FA',
  light: '#F1F3F5',
  main: '#6B7280', 
  dark: '#4B5563',
  darkest: '#272D36',
  contrastText: '#FFFFFF' // Main should typically have white contrast
});

export const olive = withAlphas({
  lightest: '#FCF9EA',
  light: '#F9F2C9',
  main: '#808619',
  dark: '#575D13',
  darkest: '#363B0E',
  contrastText: '#FFFFFF'
});

export const lime = withAlphas({
  lightest: '#F9FDEF',
  light: '#F0F7D0',
  main: '#C4DE00',
  dark: '#8CA800',
  darkest: '#5E7300',
  contrastText: '#000000' // Dark shades need black contrast
});

export const amber = withAlphas({
  lightest: '#FFF8E1',
  light: '#FFECB3',
  main: '#FFC107',
  dark: '#FFA000',
  darkest: '#C77000',
  contrastText: '#000000' // Dark shades need black contrast
});

export const deepOrange = withAlphas({
  lightest: '#FFF3E0',
  light: '#FFE0B2',
  main: '#FF9800',
  dark: '#F57C00',
  darkest: '#E65100',
  contrastText: '#000000' // Dark shades need black contrast
});

export const skyBlue = withAlphas({
  lightest: '#EBF5FF',
  light: '#CCE0FF',
  main: '#0099FF',
  dark: '#006BB3',
  darkest: '#004682',
  contrastText: '#FFFFFF' 
});

export const coral = withAlphas({
  lightest: '#FFEBE8',
  light: '#FFC8BE',
  main: '#FF8A65',
  dark: '#CC5D3F',
  darkest: '#983B23',
  contrastText: '#FFFFFF' 
});

export const mint = withAlphas({
  lightest: '#EEFAF5',
  light: '#CCEAD5',
  main: '#23C16B',
  dark: '#068247',
  darkest: '#0C5530',
  contrastText: '#FFFFFF'  
});

export const lavender = withAlphas({
  lightest: '#F7F4FF', 
  light: '#EAD9FE',
  main: '#C397D7',
  dark: '#8A5BB9',
  darkest: '#5C3D86',
  contrastText: '#FFFFFF'  
});

export const peach = withAlphas({
  lightest: '#FFF0EB',
  light: '#FFE0CC',
  main: '#FFAC6C',
  dark: '#CC793A',
  darkest: '#985022',
  contrastText: '#000000' 
});

export const plum = withAlphas({
  lightest: '#F9F3FA',
  light: '#F1E5F4',
  main: '#95559C',
  dark: '#65336D',
  darkest: '#432048',
  contrastText: '#FFFFFF'  
});

export const sage = withAlphas({
  lightest: '#F1F8F5',
  light: '#E2F1E8',
  main: '#73AA80',
  dark: '#507D5B',
  darkest: '#35563C',
  contrastText: '#FFFFFF'  
});

export const lemon = withAlphas({
  lightest: '#FEFCE8',
  light: '#FEF9C3',
  main: '#F9E79F',
  dark: '#C7B874',
  darkest: '#908753',
  contrastText: '#000000' 
});

export const terracotta = withAlphas({
  lightest: '#FBE9E7',
  light: '#FFCCBC',
  main: '#E65100',
  dark: '#B33600',
  darkest: '#822600',
  contrastText: '#FFFFFF'  
});

export const aqua = withAlphas({
  lightest: '#DFF8F9',
  light: '#C7F2F4',
  main: '#00C7CA',
  dark: '#00959B',
  darkest: '#006B70',
  contrastText: '#FFFFFF'  
});
