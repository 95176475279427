import { useContext, useEffect } from "react";
import { createContext, useState } from "react";

export const SchoolContext = createContext({});

export const SchoolProvider = (props) => {
  const { children } = props;
  const [grades, setGrades] = useState([]);
  const [gradesQuickList, setGradesQuickList] = useState([]);
  const [gradesSectionsList, setGradesSectionsList] = useState([]);
  const [sectionsById, setSectionsById] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [showGloabalLoader, setshowGloabalLoader] = useState(false);
  const [studentsAllFeeRelatedDetails, setStudentsAllFeeRelatedDetails] = useState([]);
  const [studentsAllFeeRelatedDetailsByYearly, setStudentsAllFeeRelatedDetailsByYearly] = useState(
    []
  );
  const [parentSelectedStudent, setParentSelectedStudent] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [studentDynamicField, setStudentDynamicField] = useState([]);
  const gradeIndexes = [
    [-3, "Pre School"],
    [-2, "Nursery"],
    [-1, "Class LKG"],
    [0, "Class UKG"],
    [1, "Class 1"],
    [2, "Class 2"],
    [3, "Class 3"],
    [4, "Class 4"],
    [5, "Class 5"],
    [6, "Class 6"],
    [7, "Class 7"],
    [8, "Class 8"],
    [9, "Class 9"],
    [10, "Class 10"],
    [11, "Class 11"],
    [12, "Class 12"],
  ];

  const gradeIndexesShortLabel = [
    [-3, "Pre"],
    [-2, "Nur"],
    [-1, "LKG"],
    [0, "UKG"],
    [1, "1"],
    [2, "2"],
    [3, "3"],
    [4, "4"],
    [5, "5"],
    [6, "6"],
    [7, "7"],
    [8, "8"],
    [9, "9"],
    [10, "10"],
    [11, "11"],
    [12, "12"],
  ];

  const getGradeIndexToLabel = (index, short = false) => {
    if (short) {
      const gradeShortLebel = gradeIndexesShortLabel.find((gradeIndex) => index === gradeIndex[0]);
      return gradeShortLebel[1];
    }
    const gradeLebel = gradeIndexes.find((gradeIndex) => index === gradeIndex[0]);
    return gradeLebel[1];
  };

  let gradeIndexToLabel = {};
  for (let index = 0; index < gradeIndexes.length; index++) {
    gradeIndexToLabel[gradeIndexes[index][0]] = gradeIndexes[index][1];
  }

  let gradeIndexToShortLabel = {};
  for (let index = 0; index < gradeIndexesShortLabel.length; index++) {
    gradeIndexToShortLabel[gradeIndexesShortLabel[index][0]] = gradeIndexesShortLabel[index][1];
  }

  useEffect(() => {
    const byIds = {};
    const gradeTotal = {};
    for (let index = 0; index < gradesSectionsList.length; index++) {
      const section = gradesSectionsList[index];
      byIds[section.id] = section;

      if (!gradeTotal[section.grade_id]) {
        gradeTotal[section.grade_id] = 0;
      }
      gradeTotal[section.grade_id] += section.students_count;
    }
    setSectionsById(byIds);

    const tempGrades = [];
    for (let index = 0; index < gradesSectionsList.length; index++) {
      const gradeSection = gradesSectionsList[index];
      if (!tempGrades.find((item) => item.id === gradeSection.grade_id)) {
        tempGrades.push({
          level: gradeSection.grade__level,
          id: gradeSection.grade_id,
          label: gradeIndexToLabel[gradeSection.grade__level],
          totalStudents: gradeTotal[gradeSection.grade_id],
        });
      }
    }
    setGrades(tempGrades);
  }, [gradesSectionsList]);

  useEffect(() => {
    const storedStudent = localStorage.getItem("selectedStudent");
    if (storedStudent) {
      setParentSelectedStudent(JSON.parse(storedStudent));
    }
  }, []);

  const changeParentStudent = (student) => {
    setParentSelectedStudent(student);
    localStorage.setItem("selectedStudent", JSON.stringify(student));
  };

  return (
    <SchoolContext.Provider
      value={{
        grades,
        teachers,
        gradeIndexes,
        sectionsById,
        notifications,
        gradesQuickList,
        showGloabalLoader,
        gradeIndexToLabel,
        gradeIndexToShortLabel,
        gradesSectionsList,
        studentsAllFeeRelatedDetails,
        studentsAllFeeRelatedDetailsByYearly,
        studentDynamicField,
        parentSelectedStudent,
        changeParentStudent,
        setGrades,
        setTeachers,
        setNotifications,
        setGradesQuickList,
        setshowGloabalLoader,
        getGradeIndexToLabel,
        setGradesSectionsList,
        setStudentDynamicField,
        setStudentsAllFeeRelatedDetails,
        setStudentsAllFeeRelatedDetailsByYearly,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};

export const SchoolConsumer = SchoolContext.Consumer;

export const useSchoolContext = () => useContext(SchoolContext);
