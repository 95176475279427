import { useCallback, useEffect, useState } from "react";
import Bell01Icon from "@untitled-ui/icons-react/build/esm/Bell01";
import { Badge, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { NotificationsPopover } from "./notifications-popover";
import { useSchoolContext } from "src/contexts/school-context";
import { useAuthContext } from "src/contexts/auth-context";
import apiCalls from "src/api";

const useNotifications = () => {
  const { notifications, setNotifications } = useSchoolContext();

  // const unread = useMemo(() => {
  //   return notifications.reduce((acc, notification) => acc + (notification.read ? 0 : 1), 0);
  // }, [notifications]);

  const handleRemoveOne = useCallback((notificationId) => {
    setNotifications((prevState) => {
      return prevState.filter((notification) => notification.id !== notificationId);
    });
  }, []);

  const handleMarkAllAsRead = useCallback(() => {
    setNotifications((prevState) => {
      return prevState.map((notification) => ({
        ...notification,
        read: true,
      }));
    });
  }, []);

  return {
    handleMarkAllAsRead,
    handleRemoveOne,
    notifications,
  };
};

export const NotificationsButton = () => {
  const popover = usePopover();
  const { handleRemoveOne, handleMarkAllAsRead, notifications } = useNotifications();
  const { user } = useAuthContext();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    const lastCheckedTime = new Date(user?.settings?.last_checked_notification_at);
    const newNotifications = notifications?.filter((notification) => {
      const notificationTime = new Date(notification?.created_at);
      return notificationTime > lastCheckedTime;
    });
    setUnreadNotificationsCount(newNotifications?.length);
  }, [notifications, user]);

  const handleClickOnNotification = async () => {
    popover.handleOpen();
    await apiCalls.updateNotificationsReadTime();
    setUnreadNotificationsCount(0);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={popover.anchorRef} onClick={handleClickOnNotification}>
          <Badge color="error" badgeContent={unreadNotificationsCount}>
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        onClose={popover.handleClose}
        onMarkAllAsRead={handleMarkAllAsRead}
        onRemoveOne={handleRemoveOne}
        open={popover.open}
      />
    </>
  );
};
