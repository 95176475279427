import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import BottomNav from "../../vertical-layout/bottom-nav";
import { AppTopNav } from "../app-top-nav";

const AppLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
});

const AppLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const AppLayout = (props) => {
  const { children, navColor } = props;

  return (
    <>
      <AppTopNav color={navColor} />
      <BottomNav />

      <AppLayoutRoot>
        <AppLayoutContainer>{children}</AppLayoutContainer>
      </AppLayoutRoot>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
};
