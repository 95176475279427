import {
  blue,
  green,
  indigo,
  purple,
  teal,
  cyan,
  magenta,
  pink,
  brown,
  gray,
  olive,
  lime,
  deepOrange,
  amber,
  skyBlue,
  coral,
  mint,
  lavender,
  peach,
  plum,
  sage,
  lemon,
  terracotta,
  aqua
} from "./colors";

export const getPrimary = (preset) => {
  switch (preset) {
    case 'blue':
      return blue;
    case 'green':
      return green;
    case 'indigo':
      return indigo;
    case 'purple':
      return purple;
    case 'teal':
      return teal;
    case 'cyan':
      return cyan;
    case 'magenta':
      return magenta;
    case 'brown':
      return brown;
    case 'pink':
      return pink;
    case 'gray':
      return gray;
    case 'olive':
      return olive;
    case 'amber':
      return amber;
    case 'lime':
      return lime;
    case 'deepOrange':
      return deepOrange;
    case 'skyBlue':
      return skyBlue;
    case 'coral':
      return coral;
    case 'mint':
      return mint;
    case 'lavender':
      return lavender;
    case 'peach':
      return peach;
    case 'plum':
      return plum;
    case 'sage':
      return sage;
    case 'lemon':
      return lemon;
    case 'terracotta':
      return terracotta;
    case 'aqua':
      return aqua;
    default:
      console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
      return green;
  }
};
