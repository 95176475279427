import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import apiCalls from "src/api";
import { format } from "date-fns";
import { useSchoolContext } from "src/contexts/school-context";
import { paths } from "src/paths";
import { useNavigate } from "react-router";

export const SearchDialog = (props) => {
  const { sectionsById, gradeIndexToLabel, getGradeIndexToLabel } = useSchoolContext();
  const navigate = useNavigate();

  const { onClose, open = false, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [expandedResults, setExpandedResults] = useState({
    students: false,
    users: false,
    exams: false,
    grades: false,
    gradeSections: false,
  });
  const [expandedPages, setExpandedPages] = useState(false);

  let debounceTimer = null;

  const localOnClose = () => {
    setResults(null);
    setExpandedResults({
      students: false,
      users: false,
      exams: false,
      grades: false,
      gradeSections: false,
    });
    onClose();
  };

  const formatSearchResults = (results, searchQuery) => {
    const formattedResults = {};

    if (results?.pages) {
      formattedResults.Pages = Object.entries(results.pages).map(([path, { title, keywords }]) => {
        const matchingKeyword = keywords.find((keyword) =>
          keyword.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const displayKeyword = matchingKeyword || keywords[0];

        return {
          title: `${title} > ${displayKeyword.charAt(0).toUpperCase() + displayKeyword.slice(1)}`,
          description: keywords.join(", "),
          path,
          id: path,
        };
      });
    }

    if (results?.students?.length) {
      formattedResults.Students = results.students.map((student) => ({
        title: `${student.first_name} ${student.middle_name} ${student.last_name} ${
          sectionsById[student.grade_section]
            ? ` - ${gradeIndexToLabel[sectionsById[student.grade_section]?.grade__level]} ${
                sectionsById[student.grade_section]?.title
              }`
            : ""
        }`,
        description: [
          student.roll_number,
          format(new Date(student.date_of_birth), "dd MMM yyyy"),
        ].join(" - "),
        path: paths.viewStudent(student.id),
        id: student.id,
      }));
    }

    if (results?.users?.length) {
      formattedResults.Users = results.users.map((user) => ({
        title: `${user.first_name} ${user.last_name}`,
        description: [user.usersettings__role.toUpperCase(), `Phone:  ${user.phone}`].join(" - "),
        path: paths.viewUser(user.id),
        id: user.id,
      }));
    }

    if (results?.exams?.length) {
      formattedResults.Exams = results.exams.map((exam) => ({
        title: exam.title,
        description: `${exam.exam_type} - ${format(new Date(exam.start_date), "dd MMM yyyy")}`,
        path: paths.viewExam(exam.id),
        id: exam.id,
      }));
    }

    if (results?.grades?.length) {
      formattedResults.Grades = results.grades.map((grade) => ({
        title: getGradeIndexToLabel(grade.level),
        description: grade.description,
        path: paths.viewClass(grade.id),
        id: grade.id,
      }));
    }

    if (results?.grade_sections?.length) {
      formattedResults.GradeSections = results.grade_sections.map((section) => ({
        title: `${gradeIndexToLabel[section?.grade__level]} ${section.title}`,
        description: section.description,
        path: paths.viewClassSection(section.id),
        id: section.id,
      }));
    }

    return formattedResults;
  };

  const handleDelayedSearch = useCallback(async (searchQuery) => {
    if (searchQuery) {
      try {
        setIsLoading(true);
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(async () => {
          const resData = await apiCalls.searchGlobally({ q: searchQuery });
          setResults(formatSearchResults(resData, searchQuery));
          setIsLoading(false);
        }, 1000);
      } catch (error) {
        setIsLoading(false);
        console.error("Error occurred while searching globally", error);
      }
    }
  }, []);

  const handleSeeMore = (type) => {
    setExpandedResults((prev) => ({
      ...prev,
      [type]: true,
    }));
  };

  const handleSeeLess = (type) => {
    setExpandedResults((prev) => ({
      ...prev,
      [type]: false,
    }));
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={localOnClose} open={open} {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ px: 3, py: 2 }}
      >
        <Typography variant="h6">Search</Typography>
        <IconButton color="inherit" onClick={localOnClose}>
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <Box component="form">
          <TextField
            fullWidth
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon>
                    <SearchMdIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={(event) => handleDelayedSearch(event.target.value)}
            placeholder="Start Typing Here..."
          />
        </Box>
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <CircularProgress />
          </Box>
        )}
        {results && (
          <Stack spacing={2} sx={{ mt: 1 }}>
            {Object.keys(results).length
              ? results.Pages &&
                results.Pages.length > 0 && (
                  <Stack key="pages" spacing={0}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1">Pages</Typography>
                      {results.Pages.length > 4 && (
                        <Button onClick={() => setExpandedPages(!expandedPages)} sx={{ m: 0 }}>
                          {expandedPages ? "See Less" : "See More"}
                        </Button>
                      )}
                    </Stack>
                    <Stack divider={<Divider />}>
                      {(expandedPages ? results.Pages : results.Pages.slice(0, 4)).map((result) => (
                        <Box
                          onClick={() => {
                            localOnClose();
                            navigate(result.path);
                          }}
                          key={result.id}
                          sx={{
                            p: 0,
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.1)",
                            },
                          }}
                        >
                          <Stack alignItems="center" direction="row" spacing={2}>
                            <Typography variant="body2">{result.title}</Typography>
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                )
              : null}
            {Object.keys(results).map(
              (type, index) =>
                type !== "Pages" && (
                  <Stack key={index} spacing={0}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1">{type}</Typography>
                      {results[type].length > 3 && (
                        <Button
                          onClick={() =>
                            expandedResults[type] ? handleSeeLess(type) : handleSeeMore(type)
                          }
                          sx={{ m: 0 }}
                        >
                          {expandedResults[type] ? "See Less" : "See More"}
                        </Button>
                      )}
                    </Stack>
                    <Stack divider={<Divider />}>
                      {(expandedResults[type] ? results[type] : results[type].slice(0, 3)).map(
                        (result) => (
                          <Box
                            onClick={() => {
                              localOnClose();
                              navigate(result.path);
                            }}
                            key={result.id}
                            sx={{
                              p: 0,
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                              },
                            }}
                          >
                            <Stack alignItems="center" direction="row" spacing={2}>
                              <Typography variant="body2">
                                {result.title} {result?.description}
                              </Typography>
                            </Stack>
                          </Box>
                        )
                      )}
                    </Stack>
                  </Stack>
                )
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
