import { useEffect } from "react";
import { useTenantContext } from "src/contexts/tenant-context";
import { useSchoolContext } from "src/contexts/school-context";
import CircularLoaderWithImage from "./components/loader";
import apiCalls from "src/api";
import { Box } from "@mui/material";
// import { useSettingsContext } from "./contexts/settings-context";

const PublicPagesContainer = ({ children }) => {
  const { setTenantConfig } = useTenantContext();
  const { showGloabalLoader } = useSchoolContext();
  // const { pullRemoteThemeConfig } = useSettingsContext();

  const getThisTenantInfo = async () => {
    const resData = await apiCalls.thisTenant();
    if (resData) {
      setTenantConfig(resData);
    }
  };

  useEffect(() => {
    getThisTenantInfo();
    // pullRemoteThemeConfig();
  }, []);

  return (
    <>
      <CircularLoaderWithImage show={showGloabalLoader} />
      {children}
      <Box sx={{ pb: { md: 0, xs: 5 } }}></Box>
    </>
  );
};

export default PublicPagesContainer;
