import { tokens } from "../tokens";

export const en = {
  [tokens.common.languageChanged]: "Language changed",
  [tokens.nav.academy]: "Academy",
  [tokens.nav.account]: "Account",
  [tokens.nav.analytics]: "Analytics",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.pages]: "Pages",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.register]: "Register",
  [tokens.nav.resetPassword]: "Reset Password",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.verifyCode]: "Verify Code",
  [tokens.nav.Subjects]: "Subjects",
  [tokens.nav.classes]: "Classes",
  "nav.classes.num-3": "Class PG",
  "nav.classes.num-2": "Class Nursery",
  "nav.classes.num-1": "Class LKG",
  "nav.classes.num0": "Class UKG",
  "nav.classes.num1": "Class I",
  "nav.classes.num2": "Class II",
  "nav.classes.num3": "Class III",
  "nav.classes.num4": "Class IV",
  "nav.classes.num5": "Class V",
  "nav.classes.num6": "Class VI",
  "nav.classes.num7": "Class VII",
  "nav.classes.num8": "Class VIII",
  "nav.classes.num9": "Class IX",
  "nav.classes.num10": "Class X",
  "nav.classes.num11": "Class XI",
  "nav.classes.num12": "Class XII",
  all: "All",
  addNewClass: "Add New Class",
  editClass: "Edit Class",
  teachers: "Teachers",
  teacher: "Teacher",
  students: "Students",
  student: "Student",
  attendance: "Attendance",
  finance: "Finance",
  examination: "Examination",
  updateResult: "Update Result",
  administration: "Administration",
  fileManagement: "File Management",
  website: "Website",
  subjects: "Subjects",
  workTour: {
    attendance: {
      classSectionFilter:
        "Use this filter to select the class section you want to view attendance for.",
      takenByFilter: "Select a teacher to filter the attendance records taken by them.",
      dateRangePicker: "Choose a date range to filter attendance records by date.",
      resetFiltersBtn: "Click here to reset all filters and view all attendance records.",
      actionButton: "Click this button to view detailed attendance records for this entry.",
    },
    students: {
      addBtn: "Click here to create a student.",
      filterGender: "Select a gender to filter the students list.",
      filterGradeSection: "Choose a grade section to narrow down the search.",
      filterCaste: "Filter students by caste category.",
      filterBplStatus: "Filter students based on BPL (Below Poverty Line) status.",
      filterStudentType: "Filter by student type: Regular or Private.",
      filterStudentStatus: "Filter by student status: Temporary or Permanent.",
      clearFilters: "Click here to clear all applied filters and reset the search.",
      searchInput: "Use this search bar to quickly find specific students by name.",
      viewIcons: "Click these icons to view a student.",
      editIcons: "Click these icons to edit a student.",
      deleteIcons: "Click these icons to delete a student.",
    },
    teachers: {
      addBtn: "Click here to add a new teacher.",
      searchInput: "Use this search bar to quickly find specific teachers by name.",
      viewBtn: "Click this icon to view the teacher's profile and details.",
      editBtn: "Use this button to edit teacher information.",
      payBtn: "Click here to pay the teacher's salary.",
    },
    finance: {
      dateRangeSelector: "Select a date range to filter finance data.",
      addBtn: "Click here to add a new finance record.",
    },
    examination: {
      releaseResultButton: "Click here to release results for selected exams.",
      addExamButton: "Click here to add a new exam.",
      searchInput: "Use this search bar to quickly find specific students by name.",
      examScheduleButton: "Click here to view the exam schedule.",
      examResultsButton: "Click here to view the exam results.",
      editExamButton: "Click here to edit the exam details.",
    },
    subjects: {
      addBtn: "Click here to add a new subject.",
      verificationButton: "Use this button to verify subject information.",
      tabs: "Switch between grade levels here to view relevant subjects.",
      searchInput: "Use this field to search and filter subjects by name.",
      editButton: "Click here to edit the subject's details.",
      deleteButton: "Click here to delete the subject from the list.",
    },
    customFees: {
      addFeeButton: "Click here to add a new custom fee.",
      editFeeButton: "Click here to edit a fee.",
      deleteFeeButton: "Click here to delete a fee.",
      searchInput: "Use this field to search and filter through the fees.",
    },
    updateResult: {
      pageTitle: "You are now on the Edit Result page. Here's where you can make changes.",
      selectExamination: "Select the examination for which you want to edit results.",
      selectGrade: "Select the grade to filter students.",
      selectSubject: "Choose the subject for which you want to enter marks.",
      // marksInput: "Enter the marks for the student here.",
    },
  },
};
