export const appConfig = {
  gtmContainerId: process.env.REACT_APP_GTM_CONTAINER_ID,
  baseApiUrl: process.env.REACT_APP_API_BASE_ULR || "https://api.learnplace.in",
  muiLicenseKey:
    process.env.REACT_APP_MUI_LICENSE_KEY ||
    "3f15b04eda701117b3c43cddee8606afTz04MTQ1MSxFPTE3MzU4OTUzNzIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  firebaseAuthInfo: process.env.REACT_APP_FIREBASE_AUTH_INFO,
  pushNvapidKey: process.env.REACT_APP_FIREBASE_V_API_KEY,
  staticServer: process.env.REACT_APP_STATIC_SERVER || "https://lp-cdn.easify.xyz",
  instagramClientId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
  instagramRedirectUri: process.env.REACT_APP_INSTAGRAM_REDIRECT_URI,
  facebookClientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  facebookRedirectUri: process.env.REACT_APP_FACEBOOK_REDIRECT_URI,
  linkedInClientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  linkedInRedirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
};
